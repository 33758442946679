









import Vue from 'vue'
import { IJobRecruitmentAvailability, IUserDetails } from '@/types'
import { BaseFieldSlider } from '@/components'
import { REPOSITORY_JOB_NOTICE_PERIODS } from '@/repositiories'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_PROFILE, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_USER_PROFILE)

export default Vue.extend({
    name: 'SliderRemotePercentage',

    components: { BaseFieldSlider },

    props: {
        userDetails: {
            type: Object as () => IUserDetails,
            required: true,
        },
    },

    data() {
        return {
            remotePercentage: this.userDetails.recruitment.remotePercentage,
            isRequestInProgress: false,
        }
    },

    watch: {
        remotePercentage() {
            this.submit()
        },
    },

    methods: {
        ...mapActions({ updateUserDetails: ACTION_DASHBOARD_USER_PROFILE.UPDATE_USER_DETAILS }),

        async submit() {
            this.isRequestInProgress = true

            try {
                await this.updateUserDetails({
                    recruitment: { remotePercentage: this.remotePercentage },
                })
            } catch (e) {}

            this.isRequestInProgress = false
        },
    },
})
