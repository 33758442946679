



















import Vue from 'vue'
import { REPOSITORY_USER_SLUG_RESERVATION } from '@/repositiories'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import { BaseFieldText } from '@/components/BaseField'

const SLUG_REGEXP = /^[a-z0-9]+(?:-[a-z0-9]+)*$/

export default Vue.extend({
    name: 'SlugReservation',

    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        BaseFieldText,
    },

    data: () => ({
        isRequestInProgress: false,
        slug: '',
        isValid: true,
    }),

    async created() {
        this.isRequestInProgress = true

        try {
            this.slug = await REPOSITORY_USER_SLUG_RESERVATION.fetchSlugReservation()
        } catch (error) {
            alert(error.data.message)
        }

        this.isRequestInProgress = false
    },

    methods: {
        async reservationChangeHandler(newSlug: string) {
            this.isValid = this.validate(newSlug)
            if (!this.isValid) return

            this.isRequestInProgress = true

            try {
                this.slug = await REPOSITORY_USER_SLUG_RESERVATION.createSlugReservation(
                    newSlug
                )
            } catch (e) {
                alert(e.data.message)
            }

            this.isRequestInProgress = false
        },

        validate(slug: string): boolean {
            return slug === '' || (SLUG_REGEXP.test(slug) && slug.length > 4)
        },
    },
})
