










import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { BaseUserSkillEditable } from '@/components'
import { IUserSkillProfile, IUserSkillProfileUpdateConfig } from '@/types'
import { ACTION_DASHBOARD_USER_SKILLS, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_USER_SKILLS)

export default Vue.extend({
    name: 'Skill',

    components: { BaseUserSkillEditable },

    props: {
        userSkill: {
            type: Object as () => IUserSkillProfile,
            required: true,
        },
    },

    computed: {
        experience: {
            get(): IUserSkillProfile['yearsOfExperience'] {
                return this.userSkill.yearsOfExperience
            },
            set(yearsOfExperience: IUserSkillProfile['yearsOfExperience']) {
                this.update({ yearsOfExperience })
            },
        },

        score: {
            get(): IUserSkillProfile['score'] {
                return this.userSkill.score
            },
            set(score: IUserSkillProfile['score']) {
                this.update({ score })
            },
        },
    },

    methods: {
        ...mapActions({
            updateUserSkill: ACTION_DASHBOARD_USER_SKILLS.UPDATE_USER_SKILL,
            deleteUserSkill: ACTION_DASHBOARD_USER_SKILLS.DELETE_USER_SKILL,
        }),

        async update(updateConfig: Omit<IUserSkillProfileUpdateConfig, 'uuid'>) {
            try {
                const payload: IUserSkillProfileUpdateConfig = {
                    uuid: this.userSkill.uuid,
                    ...updateConfig,
                }

                await this.updateUserSkill(payload)
            } catch (error) {}
        },

        remove() {
            try {
                this.deleteUserSkill(this.userSkill.uuid)
            } catch (error) {}
        },
    },
})
