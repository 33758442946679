











import Vue from 'vue'
import { IUserDetails } from '@/types'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import { FieldStackOverflow, FieldRepositories } from './components'

export default Vue.extend({
    name: 'SocialNetworking',

    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        FieldStackOverflow,
        FieldRepositories,
    },

    props: {
        userDetails: {
            type: Object as () => IUserDetails,
            required: true,
        },
    },
})
