












import Vue from 'vue'
import { IUserDetails } from '@/types'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import {
    SelectNoticePeriod,
    SelectRecruitmentAvailability,
    SliderRemotePercentage,
} from './components'

export default Vue.extend({
    name: 'Recruitment',

    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        SelectNoticePeriod,
        SelectRecruitmentAvailability,
        SliderRemotePercentage,
    },

    props: {
        userDetails: {
            type: Object as () => IUserDetails,
            required: true,
        },
    },
})
