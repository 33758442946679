











import Vue from 'vue'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { IUserDetails } from '@/types'
import { BaseFieldText } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_PROFILE, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_USER_PROFILE)

export default Vue.extend({
    name: 'FieldRepositories',

    components: { BaseFieldText },

    props: {
        userDetails: {
            type: Object as () => IUserDetails,
            required: true,
        },
    },

    data() {
        return {
            urlRepositories: this.userDetails.social.urlRepositories,
            isRequestInProgress: false,
            faGithub,
        }
    },

    methods: {
        ...mapActions({ updateUserDetails: ACTION_DASHBOARD_USER_PROFILE.UPDATE_USER_DETAILS }),

        async submit() {
            this.isRequestInProgress = true

            try {
                await this.updateUserDetails({
                    social: { urlRepositories: this.urlRepositories },
                })
            } catch (e) {}

            this.isRequestInProgress = false
        },
    },
})
