




import Vue from 'vue'
import { IJobNoticePeriod, IUserDetails } from '@/types'
import { BaseFieldNoticePeriod } from '@/components'
import { REPOSITORY_JOB_NOTICE_PERIODS } from '@/repositiories'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_PROFILE, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_USER_PROFILE)

export default Vue.extend({
    name: 'SelectNoticePeriod',

    components: { BaseFieldNoticePeriod },

    props: {
        userDetails: {
            type: Object as () => IUserDetails,
            required: true,
        },
    },

    data() {
        return {
            noticePeriod: this.userDetails.recruitment.noticePeriod as
                | undefined
                | IJobNoticePeriod,
            isRequestInProgress: false,
        }
    },

    watch: {
        noticePeriod() {
            this.submit()
        },
    },

    methods: {
        ...mapActions({ updateUserDetails: ACTION_DASHBOARD_USER_PROFILE.UPDATE_USER_DETAILS }),

        async submit() {
            this.isRequestInProgress = true

            try {
                await this.updateUserDetails({
                    recruitment: { noticePeriod: this.noticePeriod },
                })
            } catch (e) {}

            this.isRequestInProgress = false
        },
    },
})
