









import Vue from 'vue'
import { IPerson, IUserDetails } from '@/types'
import { BaseFieldPerson } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_PROFILE, STORE_MODULE } from '@/enums'

const { mapActions } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_USER_PROFILE)

export default Vue.extend({
    name: 'FieldPerson',

    components: { BaseFieldPerson },

    props: {
        userDetails: {
            type: Object as () => IUserDetails,
            required: true,
        },
    },

    data() {
        return {
            person: {
                firstName: this.userDetails.firstName,
                lastName: this.userDetails.lastName,
            } as IPerson,
            isValid: false,
            isRequestInProgress: false,
        }
    },

    methods: {
        ...mapActions({ updateUserDetails: ACTION_DASHBOARD_USER_PROFILE.UPDATE_USER_DETAILS }),

        async submit() {
            if (!this.isValid) return

            this.isRequestInProgress = true

            try {
                await this.updateUserDetails(this.person)
            } catch (e) {}

            this.isRequestInProgress = false
        },
    },
})
