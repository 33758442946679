



















import Vue from 'vue'
import { IFile, IUserDetails } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { MUTATION_DASHBOARD_USER_PROFILE, STORE_MODULE } from '@/enums'
import { BaseAvatar, BaseFieldFile } from '@/components'
import { REPOSITORY_USER_DETAILS } from '@/repositiories'

const { mapMutations } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_USER_PROFILE)

export default Vue.extend({
    name: 'AvatarUploader',

    components: { BaseAvatar, BaseFieldFile },

    props: {
        userDetails: {
            type: Object as () => IUserDetails,
            required: true,
        },
    },

    data: () => ({
        files: [] as IFile[],
        isRequestInProgress: false,
    }),

    methods: {
        ...mapMutations({ setUserDetails: MUTATION_DASHBOARD_USER_PROFILE.SET_USER_DETAILS }),

        async uploadFile(files: IFile[]) {
            if (!files.length) return

            this.isRequestInProgress = true

            try {
                const urlAvatar = await REPOSITORY_USER_DETAILS.uploadUserAvatar(files[0])

                this.setUserDetails({ ...this.userDetails, urlAvatar })
            } catch (e) {}

            this.isRequestInProgress = false
        },
    },
})
