












import Vue from 'vue'
import { IUserDetails } from '@/types'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import { AvatarUploader, FieldPerson, FieldEmail } from './components'

export default Vue.extend({
    name: 'BasicData',

    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        AvatarUploader,
        FieldPerson,
        FieldEmail,
    },

    props: {
        userDetails: {
            type: Object as () => IUserDetails,
            required: true,
        },
    },
})
