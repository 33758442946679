















import Vue from 'vue'
import { IStateDashboardUserProfile, IUser, IUserDetails } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import {
    ACTION_AUTH,
    ACTION_DASHBOARD_USER_PROFILE,
    MUTATION_DASHBOARD_USER_PROFILE,
    STORE_MODULE,
} from '@/enums'
import { BaseSpinner } from '@/components'
import { BasicData, Recruitment, SocialNetworking, SlugReservation, Skills } from './components'

const { mapActions: mapActionsAuth } = createNamespacedHelpers(STORE_MODULE.AUTH)
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_USER_PROFILE
)

export default Vue.extend({
    name: 'UserProfile',

    components: {
        BaseSpinner,
        BasicData,
        Recruitment,
        SocialNetworking,
        SlugReservation,
        Skills,
    },

    computed: {
        ...(mapState(['userDetails']) as MapStateToComputed<IStateDashboardUserProfile>),
    },

    watch: {
        userDetails(newDetails?: IUserDetails, oldDetails?: IUserDetails) {
            if (!newDetails || !oldDetails) return

            if (
                newDetails.firstName !== oldDetails.firstName ||
                newDetails.urlAvatar !== oldDetails.urlAvatar
            ) {
                this.refreshUserAccount()
            }
        },
    },

    created() {
        this.fetchAndSetUserProfile()
    },

    beforeDestroy() {
        this.setUserDetails(undefined)
    },

    methods: {
        ...mapMutations({ setUserDetails: MUTATION_DASHBOARD_USER_PROFILE.SET_USER_DETAILS }),
        ...mapActionsAuth({ refreshUserAccount: ACTION_AUTH.REFRESH }),
        ...mapActions({
            fetchAndSetUserProfile: ACTION_DASHBOARD_USER_PROFILE.FETCH_AND_SET_USER_DETAILS,
        }),
    },
})
