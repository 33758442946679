















import Vue from 'vue'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import {
    BaseCard,
    BaseCardTitle,
    BaseCardBody,
    BaseSpinner,
    BaseListUserSkills,
} from '@/components'
import { FieldAddSkill, Skill } from './components'
import { ISkill, IStateDashboardUserSkills } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_DASHBOARD_USER_SKILLS, STORE_MODULE } from '@/enums'

const { mapState, mapActions } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_USER_SKILLS)

export default Vue.extend({
    name: 'Skills',

    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        BaseSpinner,
        BaseListUserSkills,
        FieldAddSkill,
    },

    data: () => ({
        skillComponent: Skill,
        faTags,
    }),

    computed: {
        ...(mapState(['userSkills']) as MapStateToComputed<IStateDashboardUserSkills>),
    },

    async created() {
        try {
            this.fetchAndSetUserSkills()
        } catch (error) {}
    },

    methods: {
        ...mapActions({
            fetchAndSetUserSkills: ACTION_DASHBOARD_USER_SKILLS.FETCH_AND_SET_USER_SKILLS,
        }),
    },
})
